import "./CardStyle.scss";
import "./OutagesCard.scss";
import ExternalLink from "../ExternalLinks";

const NetworkOutagesCard = () => {
    return (
        <div className="card">
            <div className="outage-content">
                <div className="top-flex">
                    <div className="card-header">
                        <span className="header-txt">Network maintenance</span>
                    </div>
                    <div className="outages-txt">
                        <span>Check if your location is currently experiencing planned maintenance or a network outage.</span>
                    </div>
                </div>                
                <div className="outage-link">
                    <ExternalLink preLinkTxt="" className="outage-link-padding" linkTxt="Telstra outages" linkUrl="https://outages.telstra.com.au/#/mobile"/>
                </div>
            </div>
        </div>
    )
}

export default NetworkOutagesCard;