import axios from "axios";
import { getProperty } from "../utils/config-util";

const apiGatewayUrl = getProperty("API_GATEWAY_URL", "https://nops-gateway-amqp-dev.apps.np.sdppcf.com/api/nops");

export async function nopsLogout(refreshTokenId, options) {
    const response = await axios({
        method: "POST",
        url: `${apiGatewayUrl}/identity/v1/logout`,
        data: { refreshTokenId },
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        ...options,
    });

    const data = response.data?.data;
    return data;
}