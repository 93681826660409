import { Table } from "@noa/web-shared-library";
import { useNavigate } from "react-router-dom";

const NewServicesTable = ({ column, data }) => {
    const navigate = useNavigate();

    const tableHeaders = column.map((column) => ({ title: column }))

    const onClickHandler = ({ id, data }) => {
        navigate(id);
    };

    const tableRows = data.map((row, data) => ({
        id: row.id,
        data: [
            row.serviceNumber,
            row.subscriptionId,
            row.simId,
            row.isAssigned && row?.device?.type ? row.device.type : "Unassigned",
            row.isAssigned && row?.device?.make ? row.device.make : "Unknown",
            row.isAssigned && row?.device?.model ? row.device.model : "Unknown",

        ],
        onClickHandler,
    }));

    return <Table headers={tableHeaders} rows={tableRows} maxRowsPerPage={100} numRowsPerPage={25} ableDevUrl="/assets/able-sprites.svg" />
}

export default NewServicesTable;
