import { Column, Grid, Icon } from "@able/react";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { Helmet } from "react-helmet";
import "./HelpPage.scss";
import { PageHeading } from "../../common/components/pageHeading/PageHeading";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/Breadcrumbs";

const HelpPage = () => {
    useSetBreadcrumbs([{ label: "Dashboard", to: "/" }])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Help Page"});
    }, [])

    return (
        <>
            <Helmet>
                <title>Help and Support - Telstra NOPS</title>
            </Helmet>
            <Grid>
                <Column cols={12}>
                    <PageHeading content="Help and support" />
                </Column>
                <Column cols={12}>
                    <div className="sub-header-parent">
                        <span className="sub-header">We're here to help you with any questions you have about NOPS.</span>
                    </div>
                </Column>
                <Column cols={12}>
                    <div className="help-content info-padding">
                        <div className="icon-bg">
                            <SvgIcon name="icon-contact-customer-contact-solution" className="help-support-icon" />
                        </div>
                        <div className="detail-content">
                            <span className="section-title">For all enquiries</span>
                            <dl>
                                <div className="all-enquiries-content">
                                    <dt>
                                        <Icon
                                            icon="SupportPhone"
                                            size="24"
                                            screenReaderContent=""
                                            developmentUrl="/assets/able-sprites.svg"
                                        />
                                        Telstra Help Desk
                                    </dt>
                                    <dd>
                                        13 22 00
                                    </dd>
                                    <dd>
                                        Mon-Fri, 9am-5pm
                                    </dd>
                                    <dd>
                                        or <a className="inline-anchor" target="_blank" rel="noopener noreferrer" href="https://servicecentral.service-now.com/ism/">submit a support request</a>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </Column>
            </Grid>
        </>
    )
}

export default HelpPage;