import { Navigate, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import DashboardPage from "./pages/dashboard/DashboardPage";
import ProtectedRoutes from "./common/components/ProtectedRoutes";
import LoginPage from "./pages/login/LoginPage";
import LogoutPage from "./pages/logout/LogoutPage";
import HelpPage from "./pages/help/HelpPage";
import ServicesPage from "./pages/services/ServicesPage";
import ServicePage from "./pages/service/ServicePage";
import { getProperty } from "./utils/config-util";

function AppRouter() {
    // Get gTag from production .env file
    const gTag = getProperty("G_TAG", "");
    // Initialize google analytic for production environment. As gtag will be added for prod config only.
    if (gTag !== "") {
        ReactGA.initialize(gTag);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }

    const returnPath = sessionStorage.getItem("return_path");
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/loggedout" element={<LogoutPage />} />
            <Route path="*" element={<h2>Page Not Found</h2>} />
            <Route element={<ProtectedRoutes />}>
                <Route exact path="/" element={<DashboardPage />} />
                <Route path="/contact" element={<HelpPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/services/:id" element={<ServicePage />} />
                <Route path="/oauthCallback" element={<Navigate to={returnPath || "/"} />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
