import "./EntrepriseInformation.scss";

const EntrepriseInfo = (props) => {
    return (
        <div className="row justify-content">
            <h2 className="info">
                <span>Enterprise service info</span>
            </h2>
            <p>
                The average speed improvement is the average speed received with Accelerator during active use in a 24-hour cycle.
                Average speed without Accelerator is the predicted speed without Accelerator. This is based on the daily average speed that we estimate a user on our network in similar radio conditions would have experienced without Accelerator, using national network performance data.
            </p>

            <p>
                Accelerator reporting shows the average received speed during active use, measured daily. Note that times of inactivity (such as when the user’s device is in standby on their desk or in their pocket, or when connected to Wi-Fi) are not counted in this measurement. Please note that these measurements are different to speed-testing tools like Ookla’s Speed Test app, which measure the limits of connection over a very short period (typically within 30 seconds). As such, results from speed testing tools may differ to the average active use speeds outlined here.
            </p>
            <ul>
                <li>
                    The results displayed show the speed uplift for the service while the device has been using Telstra's 4G and 5G networks. For 5G enabled devices connected to our 5G network, the device can use aspects of both our 4G and 5G networks simultaneously. Note the above reporting represents uplifts delivered via our 4G network and some aspects of our 5G network, meaning the received performance may be faster or slower than what is shown. We anticipate full 5G speed uplift reporting will be available soon.
                </li>
            </ul>
            <p>
                If there is missing data in the above reporting, this could be because:
            </p>
            <ul>
                <li>
                    <p>
                        The mobile service has not been used yet. The reporting system takes up to two days for data to display following first usage of the service.                    </p>
                </li>
                <li>
                    <p>
                        The mobile service has been connected to Wi-Fi and not our mobile network.
                    </p>
                </li>
                <li>
                    <p>
                        The user’s SIM is in a 3G-only device (Accelerator only works on our 4G and 5G networks).
                    </p>
                </li>
                <li>
                    <p>
                        Our network and reporting systems are not always fault-free. In rare circumstances data can be missing from the daily report due to a network or system fault.                    </p>
                </li>
            </ul>
        </div>
    );
};

export default EntrepriseInfo;
