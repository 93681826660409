import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { fetchSubscriptionById } from "../../apis/subscriptions-apis";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/Breadcrumbs";
import EntrepriseInfo from "./components/EntrepriseInformation/EntrepriseInformation";
import AverageMonthlyImprovement from "./components/MonthlyPerformanceCards/AverageMonthlyImprovement";
import AverageMonthlyPerformance from "./components/MonthlyPerformanceCards/AverageMonthlyPerformance";
import NopsDailyChart from "./components/NopsDailyChart/NopsDailyChart";
import SubscriptionInformation from "./components/SubscriptionDetails/SubscriptionDetails";

import "./ServicePage.scss";

const ServicePage = () => {
    const { id } = useParams();
    const { data: subscriptionDetails, isLoading } = useQuery(["subscription", id], () => fetchSubscriptionById(id));
    useSetBreadcrumbs([
        { label: "Dashboard", to: "/" },
        { label: "Services", to: "/services" },
    ]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Service Page" });
    }, [])

    return (
        <>
            <Helmet>
                <title>Service Performance - Telstra NOPS</title>
            </Helmet>
            {!isLoading && (
                <>
                    <SubscriptionInformation subscriptionDetails={subscriptionDetails} />
                    <div className="performance-cards-container">
                        <AverageMonthlyPerformance subscriptionId={subscriptionDetails.subscriptionId} />
                        <AverageMonthlyImprovement subscriptionId={subscriptionDetails.subscriptionId} />
                    </div>
                    <div>
                        <h2 className="info">Downlink throughput</h2>
                        <NopsDailyChart subscriptionId={subscriptionDetails.subscriptionId} />
                    </div>
                    <EntrepriseInfo />
                </>
            )}
        </>
    );
};
export default ServicePage;

