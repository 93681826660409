export function filterInventory(subscriptions, fieldValue) {
    let matches = [];
    let fieldValueLower = fieldValue.toLowerCase();

    let filteredInventory = subscriptions.filter((sub) => {
        const { simId, subscriptionId, serviceNumber, description, name } = sub;

        if (simId && simId.includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: simId });
            return true;
        }

        if (subscriptionId && subscriptionId.includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: subscriptionId });
            return true;
        }

        if (serviceNumber && serviceNumber.includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: serviceNumber });
            return true;
        }

        if (description && description.toLowerCase().includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: description });
            return true;
        }

        if (name && name.toLowerCase().includes(fieldValueLower)) {
            matches.push({ id: sub.id, value: name });
            return true;
        }

        return false;
    });

    return { filteredInventory, matches };
}
