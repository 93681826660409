import { IconButton } from "@able/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../../store/app-store"
import "./DropDown.scss";

function DropDownItem({ children, handler }) {
    return (
        <li className="nav-item nav-item-unselected">
            <button className="button-reset" onClick={handler}>
                {children}
            </button>
        </li>
    );
}

function DropDownMenu({ closeDropdown = () => { }, isOpen = false }) {
    const logout = useStore((state) => state.logout);
    const navigate = useNavigate();

    const handleLogOut = () => {
        logout();
        navigate("/loggedout");
    };

    return (
        <ol
            aria-label="Account Menu"
            id="accountMenu"
            className={`dropdown ${isOpen && "dropdown-visible"}`}
            onBlur={closeDropdown}
        >
            <DropDownItem handler={handleLogOut}>Sign out</DropDownItem>
        </ol>
    );
}

function DropDown() {
    const [open, setOpen] = useState(false);
    const closeDropdown = () => setOpen(false);

    return (
        <div>
            <IconButton
                aria-expanded={open}
                aria-label="Account"
                aria-controls="accountMenu"
                icon="Profile"
                size="32"
                developmentUrl="/assets/able-sprites.svg"
                events={{ onClick: () => setOpen(!open) }}
            />
            <DropDownMenu closeDropdown={closeDropdown} isOpen={open} />
        </div>
    );
}

export default DropDown;