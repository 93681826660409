import { Column, Grid } from "@able/react";
import Highcharts from "highcharts";
import { useEffect, useState } from "react";
import useMonthlyData from "../../../../hooks/useMonthlyData";
import dayjs from "../../../../utils/dayjs-util";
import { formatNetworkThroughputData, formatSubscriptionThroughPutData } from "../../../../utils/throughput-data-util";
import FilterDropDownMenu from "../FilterDropDownMenu/FilterDropDownMenu";
import HighChartsWrapper from "./HighChartsWrapper";
import ThroughputChartNoResults from "./NoResultsMessage";
import "./NopsDailyChart.scss";
import ThroughputValues from "./ThroughputValues";
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/drilldown")(Highcharts);

function NopsDailyChart({ subscriptionId }) {

    const [displayLastDays, setDisplayLastDays] = useState(true);
    const [displayCurrentDay, setDisplayCurrentDay] = useState(false);
    const [displayCurrentMonth, setDisplayCurrentMonth] = useState(false);
    const [displayLastMonth, setDisplayLastMonth] = useState(false);
    const [displayMonthBefore, setDisplayMonthBefore] = useState(false);
    const [period, setPeriod] = useState("daily");
    const [data, setData] = useState({});
    const [subscriptionThroughputData, setsubscriptionThroughputData] = useState([]);
    const currentDate = dayjs().tz("Australia/Melbourne");

    let dateTimeFrom;
    let dateTimeTo;
    const periodVar = displayCurrentDay ? "hourly" : "daily";

    if (displayLastDays) {
        dateTimeFrom = currentDate.subtract(30, "day").startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
    } else if (displayCurrentDay) {
        dateTimeFrom = currentDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss");
    } else if (displayCurrentMonth) {
        dateTimeFrom = currentDate.startOf("month").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.endOf("month").utc().format("YYYY-MM-DDTHH:mm:ss");
    } else if (displayLastMonth) {
        dateTimeFrom = currentDate.subtract(1, "month").startOf("month").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.subtract(1, "month").endOf("month").utc().format("YYYY-MM-DDTHH:mm:ss");
    } else if (displayMonthBefore) {
        dateTimeFrom = currentDate.subtract(2, "month").startOf("month").utc().format("YYYY-MM-DDTHH:mm:ss");
        dateTimeTo = currentDate.subtract(2, "month").endOf("month").utc().format("YYYY-MM-DDTHH:mm:ss");
    }

    const { networkData, subscriptionData, isLoading, isSuccess } = useMonthlyData(subscriptionId, periodVar, dateTimeTo, dateTimeFrom);

    useEffect(() => {
        if (!isLoading && isSuccess) {
            setsubscriptionThroughputData(subscriptionData);
            const formattedData = formatSubscriptionThroughPutData(subscriptionData, networkData, periodVar);
            const formattedNetworkData = formatNetworkThroughputData(networkData, periodVar);
            setData({
                filteredData: formattedData,
                networkThroughPut: formattedNetworkData,
                barKey: "downLink",
                period: periodVar,
            });
        }
    }, [subscriptionData, networkData, isLoading, isSuccess, periodVar]);

    return (
        <>
            <Grid className="throughput-content-detail">
                <Column vxs={12} bsm={12} bmd={12} blg={12}>
                    <FilterDropDownMenu
                        setDisplayLastDays={setDisplayLastDays}
                        setDisplayCurrentDay={setDisplayCurrentDay}
                        setDisplayCurrentMonth={setDisplayCurrentMonth}
                        setDisplayLastMonth={setDisplayLastMonth}
                        setDisplayMonthBefore={setDisplayMonthBefore}
                        setPeriod={setPeriod}
                    />
                </Column>
                {!isLoading && isSuccess && (
                    <>
                        <Column vxs={12} bsm={3} bmd={3} blg={3}>
                            <div className="left-content">
                                <ThroughputValues
                                    subscriptionThroughput={subscriptionThroughputData?.series}
                                    period={period}
                                ></ThroughputValues>
                            </div>
                        </Column>
                        <Column vxs={12} bsm={9} bmd={9} blg={9}>
                            <HighChartsWrapper
                                subscriptionId={subscriptionId}
                                subscriptionData={subscriptionData}
                                formattedSubscriptionThroughputData={data.filteredData}
                                formattedNetworkThroughputData={data.networkThroughPut}
                                period={period}
                                setPeriod={setPeriod}
                                dateTimeFrom={dateTimeFrom}
                                dateTimeTo={dateTimeTo}
                                setsubscriptionThroughputData={setsubscriptionThroughputData}
                                selectedOption={displayCurrentDay ? "currentDayData" : "monthlyData"}
                            />
                        </Column>
                    </>
                )}
                {!isLoading && !isSuccess && <ThroughputChartNoResults />}
                {isLoading && !isSuccess && (
                    <Column vxs={12} bsm={12} bmd={12} blg={12}>
                        <div className="loading loader-align-center">
                            Loading {displayCurrentDay ? "today's" : "monthly"} performance data...
                        </div>
                    </Column>
                )}
            </Grid>
        </>
    );
}

export default NopsDailyChart;