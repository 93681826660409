import { useQuery } from "@tanstack/react-query";
import {
    fetchNetworkThroughputHistoryData,
    fetchSubscriptionThroughputHistoryData,
} from "../apis/subscriptions-apis";

function useMonthlyData(subscriptionId, period, dateTimeTo, dateTimeFrom) {
    const params = {
        period,
        dateTimeTo,
        dateTimeFrom,
    };

    const {
        data: subData,
        isLoading: subLoading,
        isSuccess: subSuccess,
        error: subError,
    } = useQuery({
        queryKey: ["subscription", subscriptionId, params],
        queryFn: () => fetchSubscriptionThroughputHistoryData(subscriptionId, { params }),
    });

    const {
        data: netData,
        isLoading: netLoading,
        isSuccess: netSuccess,
        error: netError,
    } = useQuery({
        queryKey: ["network", subscriptionId, params],
        queryFn: () => fetchNetworkThroughputHistoryData(subscriptionId, { params }),
    });

    // any error has occurred
    return {
        subscriptionData: subData,
        networkData: netData,
        isLoading: subLoading || netLoading,
        isSuccess: subSuccess || netSuccess,
        error: subError || netError,
    };
}

export default useMonthlyData;