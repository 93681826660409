import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useOnRouteChange } from "../../hooks/useOnRouteChange";
import useStore from "../../store/app-store";
import AppLayout from "./AppLayout";

const ProtectedRoutes = () => {
    const initializeApp = useStore((state) => state.initializeApp);
    const initialized = useStore((state) => state.initialized);
    const loginError = useStore((state) => state.loginError);
    const loggedIn = useStore((state) => state.loggedIn);
    const setAutoFocus = useStore((state) => state.setAutoFocus);
    useOnRouteChange(() => setAutoFocus(true));

    useEffect(() => {
        const controller = new AbortController();
        if (!initialized) {
            initializeApp({ signal: controller.signal });
        }

        return () => {
            controller.abort();
        };
    }, [initialized, initializeApp]);

    if (!loggedIn) {
        return (
            <div className="container">
                <div className="content-box">
                    <h2>Welcome to NOPS customer portal</h2>
                    <p>
                        {!initialized && "Initializing..."}
                        {loginError && loginError.message}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <AppLayout>
            <Outlet />
        </AppLayout>
    );
};

export default ProtectedRoutes;
