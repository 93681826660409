import { ActionButton } from "@able/react";
import { useQueries } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchInventoryCountData } from "../../../../apis/subscriptions-apis";
import "./CardStyle.scss";

const ServiceSummaryCard = ({ tenancy }) => {
    const navigate = useNavigate();
    const results = useQueries({
        queries: [
            {
                queryKey: ["InventoryCount", tenancy],
                queryFn: async () => {
                    const data = await fetchInventoryCountData(tenancy?.tenancyId);
                    return data;
                },
                retry: false,
            },
        ],
    });

    const isLoading = results.some((result) => result.isLoading);

    return (
        <div className="card">
            <div className="card-upper-content">
                <div className="upper-content-flex">
                    <div className="card-header">
                        <span className="header-txt">Service summary</span>
                    </div>
                    {!isLoading && (
                        <dl className="service-count">
                            <dt>Total services</dt>
                            <dd>{results[0].data?.numSubsTotal}</dd>
                        </dl>
                    )}
                    {isLoading && <div>Loading data....</div>}
                </div>
                <ActionButton
                    label="View all services"
                    variant="LowEmphasis"
                    element="button"
                    developmentUrl="./assets/able-sprites.svg"
                    events={{ onClick: () => navigate("/services") }}
                    className="action-btn"
                />
            </div>
        </div>
    );
};

export default ServiceSummaryCard;
