import { Breadcrumbs } from "@able/react";
import useStore from "../../../store/app-store";

export const useSetBreadcrumbs = (breadcrumbsArray) => {
    const setBreadcrumbs = useStore((state) => state.setBreadcrumbs);

    setBreadcrumbs(breadcrumbsArray);

    return;
};

const AbleBreadcrumbs = () => {
    const breadcrumbs = useStore((state) => state.breadcrumbs);

    if (breadcrumbs.length > 0) {
        return (
            <Breadcrumbs
                breadcrumbs={breadcrumbs}
                setBackgroundToWhite={true}
                developmentUrl="./assets/able-sprites.svg"
            />
        );
    }

    return null;
};

export default AbleBreadcrumbs;
