import { useEffect, useRef } from "react";

/**
 * This hook helps detect if user has focused out/away from component using a click
 * or keydown event.
 *
 * Assign the returned ref to your desired component, provide a callback called on triggered event,
 * and provide boolean value for the events available to listen for.
 *
 * @param {function} callback Function passed to hook to be invoked by event handler.
 * @param {boolean} listenClicks Set true if you want to listen for click events.
 * @param {boolean} listenTabs Set true if you want to listen for tab events.
 * @returns
 */
export const useNavigatedOutside = ({ callback, listenClicks = false, listenTabs = false }) => {
  const ref = useRef();

  useEffect(() => {
    const callbackHandler = ({ current, target }) => {
      if (current && !current.contains(target)) {
        callback();
      }
    };

    const eventHandler = (event) => {
      switch (event.type) {
        case "click":
          callbackHandler({ current: ref.current, target: event.target });
          break;
        case "keydown":
          if (event.key === "Tab") {
            callbackHandler({ current: ref.current, target: event.target });
          }
          break;
        default:
          return;
      }
    };
    if (listenClicks) document.addEventListener("click", eventHandler, true);
    if (listenTabs) document.addEventListener("keydown", eventHandler, true);

    return () => {
      if (listenClicks) document.removeEventListener("click", eventHandler, true);
      if (listenTabs) document.removeEventListener("keydown", eventHandler, true);
    };
  }, [callback, listenClicks, listenTabs]);

  return ref;
};

