export const ArrowUpIcon = ({ developmentUrl }) => {
    const spriteRef = developmentUrl
        ? developmentUrl + "#BackToTop"
        : "//www.telstra.com.au/content/dam/tcom/able/able-sprites.svg?v27#BackToTop";

    return (
        <svg className="able-icon" style={{ width: 24, height: 24 }} role="img" aria-hidden="true" focusable="false">
            <use style={{ fill: "green" }} href={spriteRef} />
        </svg>
    );
};

export const ArrowDownIcon = ({ developmentUrl }) => {
    const spriteRef = developmentUrl
        ? developmentUrl + "#BackToTop"
        : "//www.telstra.com.au/content/dam/tcom/able/able-sprites.svg?v27#BackToTop";

    return (
        <svg
            className="able-icon"
            style={{ width: 24, height: 24, transform: "rotate(180deg)" }}
            role="img"
            aria-hidden="true"
            focusable="false"
        >
            <use style={{ fill: "red" }} href={spriteRef} />
        </svg>
    );
};

export const ExternalIcon = ({ developmentUrl }) => {
    const spriteRef = developmentUrl
        ? developmentUrl + "#OpenExternal"
        : "//www.telstra.com.au/content/dam/tcom/able/able-sprites.svg?v27#OpenExternal";

    return (
        <svg
            className="able-icon"
            style={{ width: 24, height: 24 }}
            role="img"
            aria-hidden="true"
            focusable="false"
        >
            <use style={{ fill: "rgb(0, 98, 218)" }} href={spriteRef} />
        </svg>
    );
};