import { ActionButton } from "@able/react";
import { Link, useLocation } from "react-router-dom";
import DropDown from "../dropDown/DropDown";
import MobileNavigation from "./MobileNavigation";
import "./Navigation.scss";

const Navigation = () => {
    const { pathname } = useLocation();

    return (
        <header className="navigation-bar">
            <Link aria-label="Telstra NOPS" className="logo" to="/">
                <img src="/images/logoBaseEarth60+Sky60.svg" className="telstra-icon" alt="Telstra" />
                <div className="lanes-heading" aria-hidden="true">
                    NOPS
                </div>
            </Link>
            <nav className="nav-container">
                <ol className="nav-items">
                    <li>
                        <Link
                            className={`nav-item ${pathname === "/services" ? `nav-item-selected` : `nav-item-unselected`
                                }`}
                            to="/services"
                        >
                            Services
                        </Link>
                    </li>
                    <li>
                        <ActionButton
                            developmentUrl="/assets/able-sprites.svg"
                            element="Link"
                            icon="Help"
                            label=""
                            to="/contact"
                            variant="LowEmphasisBig"
                            aria-label="Help and Support"
                            aria-hidden="false"
                        />
                    </li>
                    <li>
                        <DropDown />
                    </li>
                </ol>
                <MobileNavigation pathname={pathname} />
            </nav>
        </header>
    );
};

export default Navigation;
