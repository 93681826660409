import { nanoid } from "nanoid";
import { getProperty } from "../utils/config-util";

export function callAuthService() {
    // Create state and nonce codes, storing them in sessionStorage for idenity login later.
    const state = nanoid();
    const nonce = nanoid();
    sessionStorage.setItem("state", state);
    sessionStorage.setItem("nonce", nonce);

    // Create Caiman Url w/ redirect.
    let caimenURL = getProperty("CAIMAN_URL", "https://fn1.myid-nonprod.telstra.com");
    let redirectURL = getProperty("REDIRECT_URL", "http://localhost:3000");
    let clientId = getProperty("CLIENT_ID", "b2b-nops-portal");
    window.location.href = caimenURL + "/identity/as/authorization.oauth2?response_type=code&client_id=" + clientId + "&redirect_uri="
        + redirectURL + "/oauthCallback&scope=openid%20b2b%20username%20profile&state=" + state + "&nonce=" + nonce
}

export const makeCaimanLogout = async (status) => {
    let caimenURL = getProperty("CAIMEN_URL", "https://fn1.myid-nonprod.telstra.com")
    let redirectURL = getProperty("REDIRECT_URL", "http://localhost:3000")
    if(status === 'BAD_REQUEST'){
        return (window.location.href = caimenURL + "/identity/idp/startSLO.ping?TargetResource="+redirectURL+ "/")
    }else{        
        return (window.location.href = caimenURL + "/identity/idp/startSLO.ping?TargetResource="+redirectURL+ "/loggedout")
    }
}