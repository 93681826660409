import create from "zustand";
import { createAutofocusSlice } from "./slices/autofocus-slice";
import { createBreadcrumbSlice } from "./slices/breadcrumb-slice";
import { createInitializeSlice } from "./slices/initialize-slice";
import { createLoginSlice } from "./slices/login-slice";
import { createSubscriptionSlice } from "./slices/subscription-slice";
import { createTenancySlice } from "./slices/tenancy-slice";
import { createTokenSlice } from "./slices/token-slice";

const log = (config) => (set, get, api) =>
    config(
        (...args) => {
            set(...args);
        },
        get,
        api
    );

const useStore = create(
    log((set, get) => ({
        ...createInitializeSlice(set, get),
        ...createLoginSlice(set, get),
        ...createTokenSlice(set, get),
        ...createTenancySlice(set, get),
        ...createSubscriptionSlice(set, get),
        ...createAutofocusSlice(set, get),
        ...createBreadcrumbSlice(set),
    }))
);

export default useStore;
