import dayjs from "dayjs";
import React from "react";
import { ArrowUpIcon } from "../../../../common/components/ableIcons/AbleIcons";
import { calculateAveragePerformance, processMonthlyData } from "../../../../hooks/processMonthlyData";
import useMonthlyData from "../../../../hooks/useMonthlyData";
import ThroughputChartNoResults from "../NopsDailyChart/NoResultsMessage";
import "./AverageMonthlyPerformance.scss";

function AverageMonthlyImprovement({ subscriptionId }) {
    //APIs params
    const dateTimeTo = dayjs()
        .tz("Australia/Melbourne")
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
    const dateTimeFrom = dayjs()
        .tz("Australia/Melbourne")
        .subtract(5, "day")
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
    const period = "monthly";

    //calling API endpoints
    const { networkData, subscriptionData, isLoading, isSuccess } = useMonthlyData(subscriptionId, period, dateTimeTo, dateTimeFrom);

    //Processing and formatting results
    const { networkDownlink, subscriptionDownlink } = processMonthlyData(subscriptionData, networkData);
    const averageData = calculateAveragePerformance(networkDownlink, subscriptionDownlink);


    //rendering 
    return (
        <div className="performance-card">
            {/* When the downlink values are null or an error occurred while fetching data*/}
            {(!isLoading && networkDownlink === null && subscriptionDownlink === null) && (
                <>
                    <h2 className="performance-card-title"> Average Improvement</h2>
                    <ThroughputChartNoResults />
                </>
            )}

            {/* When data is still loading*/}
            {(isLoading && !isSuccess) && (
                <>
                    <h2 className="performance-card-title"> Average Improvement</h2>
                    <div className="performance-with-nops-value">
                        loading...
                    </div>
                    <div className="performance-without-nops-value">
                        Last 30 Days with Accelerator <br />
                        Without Accelerator: loading...
                    </div>
                </>
            )}

            {/* When data is done loading and the downlink values are available */}
            {(!isLoading && isSuccess && networkDownlink !== null && subscriptionDownlink !== null) && (
                <>
                    <h2 className="performance-card-title"> Average Improvement</h2>
                    <div className="performance-with-nops-value">
                        <span className="performance-arrow-icon">
                            <ArrowUpIcon developmentUrl="/assets/able-sprites.svg" />
                        </span>
                        {`${averageData}%`}
                    </div>
                    <div className="performance-without-nops-value">
                        Last 30 Days with Accelerator <br />
                        Without Accelerator: {`${networkDownlink}Mbps`}
                    </div>
                </>
            )}
        </div>
    );
}

export default AverageMonthlyImprovement;
