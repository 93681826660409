import { Icon } from "@able/react";
import { useEffect } from "react";
import { PageHeading } from "../../../../common/components/pageHeading/PageHeading";
import useStore from "../../../../store/app-store";
import WelcomeDetails from "../../../dashboard/components/WelcomeDetails";
import "./SubscriptionDetails.scss";

const useSubscriptionData = () => {
    const tenancyData = useStore((state) => state.tenancyData);
    const fetchTenancy = useStore((state) => state.fetchTenancy);

    useEffect(() => {
        const controller = new AbortController();
        if (tenancyData === null) {
            fetchTenancy({ signal: controller.signal }).catch((e) => console.error(e));
        }
        return () => controller.abort();
    }, [tenancyData, fetchTenancy]);

    return { tenancyData };
};

const SubscriptionInformation = ({ subscriptionDetails }) => {
    const { tenancyData } = useSubscriptionData();
    return (
        <>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <PageHeading content={`Service - ${subscriptionDetails?.serviceNumber || ""}`} />
                <WelcomeDetails tenancyName={tenancyData?.tenancyName} />
            </div>

            <dl className="flex-dl" aria-label="subscription information">
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                        Service number
                    </dt>
                    <dd>{subscriptionDetails?.serviceNumber || "-"}</dd>
                </div>
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                        IMSI
                    </dt>
                    <dd>{subscriptionDetails?.subscriptionId || "-"}</dd>
                </div>
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="SIM" developmentUrl="/assets/able-sprites.svg" />
                        SIM serial
                    </dt>
                    <dd>{subscriptionDetails?.simId || "-"}</dd>
                </div>
                <div className="info-grouping">
                    <dt>
                        <Icon size="32" icon="Mobile" developmentUrl="/assets/able-sprites.svg" />
                        Device
                    </dt>
                    <dd>
                        {(subscriptionDetails?.device?.name || "Unknown") + " " + (subscriptionDetails?.device?.model || "")}
                    </dd>
                </div>
            </dl>
        </>
    );
}

export default SubscriptionInformation;
