import { Column, Grid } from "@able/react";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useSetBreadcrumbs } from "../../common/components/breadcrumbs/Breadcrumbs";
import { PageHeading } from "../../common/components/pageHeading/PageHeading";
import useStore from "../../store/app-store";
import { decodeToken } from "../../utils/token-util";
import "./DashboardPage.scss";
import ExternalLink from "./components/ExternalLinks";
import GreetingIllustration from "./components/GreetingIllustration";
import WelcomeDetails from "./components/WelcomeDetails";
import NetworkOutagesCard from "./components/cards/OutagesCard";
import ServiceSummaryCard from "./components/cards/ServicesCard";

const DashboardPage = () => {
    let { firstName } = decodeToken(sessionStorage.getItem("id_token"));

    const tenancyData = useStore((state) => state.tenancyData);
    const fetchTenancy = useStore((state) => state.fetchTenancy);
    useSetBreadcrumbs([]);

    console.log({ tenancyData });
    console.log({ fetchTenancy });

    useEffect(() => {
        const controller = new AbortController();
        if (tenancyData === null) {
            fetchTenancy({ signal: controller.signal }).catch((e) => console.error(e));
        }
        return () => controller.abort();
    }, [tenancyData, fetchTenancy]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Dashboard Page"});
    }, []);

    function getGreeting() {
        let curHr = new Date().getHours();
        if (curHr < 12) return "Morning, " + firstName;
        if (curHr < 18) return "Afternoon, " + firstName;
        if (curHr < 23) return "Evening, " + firstName;
        else return "Hello, " + firstName;
    }

    return (
        <>
            <Helmet>
                <title>Telstra NOPS</title>
            </Helmet>
            <Grid className="dashboard-container" padding={false}>
                <Column cols={6}>
                    <PageHeading content={getGreeting(firstName)} />
                    <WelcomeDetails tenancyName={tenancyData?.tenancyName} />
                </Column>
                <Column cols={12} bmd={6}>
                    <GreetingIllustration />
                </Column>

                <ExternalLink
                    className="external-link"
                    preLinkTxt="Useful Links"
                    linkTxt="Service usage and billing"
                    linkUrl="https://connectapp.telstra.com/"
                />

                {tenancyData && (
                    <ul className="dashboard-cards-container">
                        <li>
                            <article>
                                <ServiceSummaryCard tenancy={tenancyData} />
                            </article>
                        </li>
                        <li>
                            <article>
                                <NetworkOutagesCard />
                            </article>
                        </li>
                    </ul>
                )}
            </Grid>
        </>
    );
};

export default DashboardPage;
