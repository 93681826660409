import { Icon } from '@able/react';
import React, { useEffect, useRef, useState } from "react";
import "./FilterDropDownMenu.scss";

const FilterDropDownMenu = ({ setDisplayLastDays, setDisplayCurrentDay, setDisplayCurrentMonth, setDisplayLastMonth, setDisplayMonthBefore, setPeriod }) => {

    const current = new Date();
    current.getMonth();
    const currentMonth = current.toLocaleString("default", { month: "long" });

    const last = new Date();
    last.setMonth(last.getMonth() - 1);
    const lastMonth = last.toLocaleString("default", { month: "long" });

    const before = new Date();
    before.setMonth(before.getMonth() - 2);
    const monthBefore = before.toLocaleString("default", { month: "long" });

    const currentYear = new Date().getFullYear();

    const [title, setTitle] = useState("Last 30 days");
    const [open, setOpen] = useState(false);

    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
    })

    const handleClickLastDays = () => {
        setTitle("Last 30 days");
        setDisplayLastDays(true);
        setDisplayCurrentDay(false);
        setDisplayCurrentMonth(false);
        setDisplayLastMonth(false);
        setDisplayMonthBefore(false);
        setPeriod("daily");
    };

    const handleClickCurrentDay = () => {
        setTitle("Today");
        setDisplayLastDays(false);
        setDisplayCurrentDay(true);
        setDisplayCurrentMonth(false);
        setDisplayLastMonth(false);
        setDisplayMonthBefore(false);
        setPeriod("hourly");
    };

    const handleClickCurrentMonth = () => {
        setTitle(currentMonth + " " + currentYear);
        setDisplayLastDays(false);
        setDisplayCurrentDay(false);
        setDisplayCurrentMonth(true);
        setDisplayLastMonth(false);
        setDisplayMonthBefore(false);
        setPeriod("daily");
    };

    const handleClickLastMonth = () => {
        setTitle(lastMonth + " " + currentYear);
        setDisplayLastDays(false);
        setDisplayCurrentDay(false);
        setDisplayCurrentMonth(false);
        setDisplayLastMonth(true);
        setDisplayMonthBefore(false);
        setPeriod("daily");
    };

    const handleClickMonthBefore = () => {
        setTitle(monthBefore + " " + currentYear);
        setDisplayLastDays(false);
        setDisplayCurrentDay(false);
        setDisplayCurrentMonth(false);
        setDisplayLastMonth(false);
        setDisplayMonthBefore(true);
        setPeriod("daily");
    };

    return (
        <div className="filter-container">
            <h3 className="dropdown-title">Date range</h3>
            <div className="dropdown-description">Show data from a selected date range</div>
            <div ref={menuRef} className="dropdown-menu">
                <div className="dropdown-box" onClick={() => { setOpen(!open) }}>
                    <div className="dropdown-text">{title}</div>
                    <Icon className="chevron-style" icon="ChevronDown" size="16" developmentUrl="/assets/able-sprites.svg" />
                </div>
                {open && (
                    <div className="dropdown-menu-container">
                        <li className="drop-down-item" onClick={handleClickLastDays}>
                            Last 30 days
                        </li>
                        <li className="drop-down-item" onClick={handleClickCurrentDay}>
                            Today
                        </li>
                        <li className="drop-down-item" onClick={handleClickCurrentMonth}>
                            {currentMonth} {currentYear}
                        </li>
                        <li className="drop-down-item" onClick={handleClickLastMonth}>
                            {lastMonth} {currentYear}
                        </li>
                        <li className="drop-down-item" onClick={handleClickMonthBefore}>
                            {monthBefore} {currentYear}
                        </li>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilterDropDownMenu;
