export const getFrequencyInterval = (frequency) => {
    let sizeInterval = 0;

    switch (frequency.toLowerCase()) {
        case "fifteenmin":
            sizeInterval = 900;
            break;
        case "hourly":
            sizeInterval = 3600;
            break;
        case "daily":
            sizeInterval = 88000;
            break;
        case "monthly":
            sizeInterval = 2628000;
            break;
        default:
            sizeInterval = 86400;
            break;
    }
    return sizeInterval;
};