import dayjs from "./dayjs-util";

export const getTimeFormat = (timeValue, period, overridePeriod = null, isFullMonth = false) => {

    const localPeriod = (overridePeriod === null ? period.toLowerCase() : overridePeriod.toLowerCase());
    const dateFormat = isFullMonth ? "DD MMMM" : "DD MMM";
    switch (localPeriod) {
        case "daily":
            return dayjs.unix(timeValue).utc().format(dateFormat);
        case "hourly":
            return dayjs.unix(timeValue).tz('Australia/Melbourne').format('hh A');
        case "datetime":
            return dayjs.unix(timeValue).tz('Australia/Melbourne').format('DD MMM | hh A');
        case "monthly":
            return dayjs.unix(timeValue).tz("Australia/Melbourne").format(dateFormat);
        default:
            return dayjs.unix(timeValue).format("DD MMM");
    }
};

export const getAESTStartTime = (selectedDate) => {
    return dayjs.unix(selectedDate).tz("Australia/Melbourne").subtract(1, "day").startOf("day");
}

export const getAESTEndTime = (selectedDate) => {
    return dayjs.unix(selectedDate).tz("Australia/Melbourne").subtract(1, "day").endOf("day");
}