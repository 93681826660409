import { ActionButton } from "@able/react";
import { useNavigate } from "react-router-dom";
import "./LogoutPage.scss";
import { Helmet } from "react-helmet";

const LogoutPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Successfully logged out of Telstra NOPS portal</title>
            </Helmet>
            <div className="container">
                <div className="content-box">
                    <h2>You have logged out of the portal</h2>
                    <p>Got more to do? Login again below</p>
                    <ActionButton
                        label="Login"
                        element="button"
                        variant="HighEmphasis"
                        developmentUrl=""
                        events={{
                            onClick: () => navigate("/"),
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default LogoutPage;
